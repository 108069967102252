import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ProgramDetail = () => {
  const location = useLocation();
  const programData = location.state;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (programData?.img?.length > 1) {
      const intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % programData.img.length);
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [programData?.img]);

  if (!programData) {
    return <div className="text-center text-xl font-semibold mt-10">Program not found!</div>;
  }

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % programData.img.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + programData.img.length) % programData.img.length
    );
  };

  return (
    <div className="bg-[#FFF5ED] p-10 md:p-12 rounded-lg">
      <div className="text-center space-y-2">
        <h1 className="text-3xl md:text-5xl font-bold text-black">{programData.title}</h1>
      </div>

      {/* Image Slider with Left & Right Buttons */}
      <div className="mt-6 flex justify-center relative">
        <button
          onClick={handlePrev}
          className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black text-white p-2 rounded-full shadow-md"
        >
          <FaChevronLeft size={20} />
        </button>

        <div className="relative w-full md:w-3/4 rounded-2xl overflow-hidden shadow-lg" style={{ height: "500px" }}> 
          <img
            src={programData.img[currentImageIndex]}
            alt={`Slide ${currentImageIndex + 1}`}
            className="w-full h-full object-cover rounded-2xl transition-transform duration-300"
          />
        </div>

        <button
          onClick={handleNext}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black text-white p-2 rounded-full shadow-md"
        >
          <FaChevronRight size={20} />
        </button>
      </div>

      {/* Thumbnails Section */}
      <div className="flex justify-center gap-4 mt-6">
        {programData?.img.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            className={`w-16 h-16 md:w-20 md:h-20 rounded-lg object-cover border-2 cursor-pointer ${
              currentImageIndex === index ? "border-[#EB5A57]" : "border-gray-300"
            }`}
            onClick={() => setCurrentImageIndex(index)}
          />
        ))}
      </div>

      {/* About Program Section */}
      <div className="mt-12">
        <h2 className="text-2xl md:text-3xl font-bold text-black">About Programs</h2>
        <ul className="mt-4 list-disc list-inside space-y-2 text-black">
          {programData?.description
            ?.split(".")
            .filter((sentence) => sentence.trim() !== "")
            .map((sentence, index) => (
              <li key={index}>{sentence.trim()}.</li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ProgramDetail;
