import React from "react";

const AboutUs = () => {
  return (
    <section className="bg-white py-10 px-6 md:px-16 font-[Poppins]">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center">
        {/* Left: Image (Smaller) */}
        <div className="md:w-1/3 w-2/3 mb-6 md:mb-0 flex justify-center">
          <img 
            src={"https://ik.imagekit.io/bhavishya/web-final/LandingPage/about2.png"} 
            alt="About Bhavishya NGO" 
            className="w-4/5 md:w-full max-w-xs rounded-lg"
          />
        </div>

        {/* Right: Text Content */}
        <div className="md:w-2/3 text-center md:text-left md:pl-10">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">
            About Us
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            At <span className="font-semibold">Bhavishya NGO</span>, we believe that{" "}
            <span className="text-orange-600 font-medium">every child deserves access to quality education</span>.
            Our mission is to <span className="font-semibold">empower underprivileged children</span> 
            by providing free education, digital literacy, and skill development programs.
            Since our inception, we have transformed countless lives, bridging the education gap
            and creating brighter futures.
          </p>
          <a
            href="/about"
            className="inline-block bg-[#EB5A57] text-white font-semibold py-3 px-6 rounded-lg hover:bg-[#d94f4c] transition duration-300">
            Read Our Story
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
