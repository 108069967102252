import React from "react";
import {
  Company1Img,
  Company2Img,
  Company3Img,
  Company4Img,
  Company5Img,
  Company6Img,
  Company7Img,
  Company8Img,
  Company9Img,
} from "../../assets";

const Companies = () => {
  return (
    <div className="bg-primaryBg px-12 py-16 space-y-8 md:space-y-12">
      <div className="font-Cheri text-borderRed capitalize text-4xl md:text-6xl text-center">
        Changing the world is a team effort
      </div>
      <div className="font-Poppins text-xl md:text-3xl text-center">
        Our supporters believed in what we do, and most importantly, they cared.
      </div>
      <div className="lg:grid lg:grid-rows-3 lg:gap-2 items-center justify-center space-y-2">
        <div className="flex flex-col lg:flex-row items-center justify-center md:space-x-4 lg:space-x-4">
          <img
            src={Company1Img}
            alt="Company 1"
            className="w-80 h-80 object-contain lg:scale-75 md:scale-75 scale-50"
          />
          <img
            src={Company2Img}
            alt="Company 2"
            className="w-80 h-80 object-contain lg:scale-75 md:scale-75 scale-50"
          />
          <img
            src={Company3Img}
            alt="Company 3"
            className="w-80 h-80 object-contain lg:scale-75 md:scale-75 scale-50"
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center md:space-x-4 lg:space-x-4">
          <img
            src={Company4Img}
            alt="Company 4"
            className="w-80 h-80 object-contain lg:scale-75 md:scale-75 scale-50"
          />
          <img
            src={Company5Img}
            alt="Company 5"
            className="w-80 h-80 object-contain lg:scale-75 md:scale-75 scale-50"
          />
          <img
            src={Company6Img}
            alt="Company 6"
            className="w-80 h-80 object-contain md:scale-75 scale-50"
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center md:space-x-4 lg:space-x-4">
          <img
            src={Company7Img}
            alt="Company 7"
            className="w-80 h-80 object-contain lg:scale-75 md:scale-75 scale-50"
          />
          <img
            src={Company8Img}
            alt="Company 8"
            className="w-80 h-80 object-contain lg:scale-75 md:scale-75 scale-50"
          />
          <img
            src={Company9Img}
            alt="Company 9"
            className="w-80 h-80 object-contain lg:scale-75 md:scale-75 scale-50"
          />
        </div>
      </div>
    </div>
  );
};

export default Companies;
