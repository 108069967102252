// Landing Page
export const About1Bg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/about1.png?updatedAt=1689694445944";
export const About2Bg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/about2.png";
export const About3Bg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/about3.png";
export const EdSupportBg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/edsupport.png";
export const EventsBg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/events.png?updatedAt=1680110935732";
export const Hero1 =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/hero1.png?updatedAt=1680110939744";
export const Hero2 =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/hero2.png?updatedAt=1680110938984";
export const Hero3 =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/hero3.png?updatedAt=1680110938321";
export const LogoPhone =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/LogoPhone.png?updatedAt=1680110933262";
export const OverlayHeroBg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/overlayHeroBg.png?updatedAt=1680110933266";
export const SponsorshipBg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/sponsorship.png?updatedAt=1680110935619";
export const TimelineImg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/timeline'.png?updatedAt=1689880535617";
export const CenterNoida71 =
  "https://ik.imagekit.io/bhavishya/web-final/Center/NoidaSector71-Center.jpeg?updatedAt=1689407241196";
export const Centre1 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/Centers/Center1.png?updatedAt=1680110950176";
export const Centre2 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/Centers/Center2.png?updatedAt=1680110950219";
export const Centre3 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/Centers/Center3.png?updatedAt=1680110950120";
export const CloudImg =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/cloudImg.png?updatedAt=1680110949548";
export const StarBorder =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/DigitalMedia/StarBorder.png?updatedAt=1680110953519";
export const QRCode =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/qrcode.jpeg?updatedAt=1691503327203";
export const EdSupportPageImg =
  "https://ik.imagekit.io/bhavishya/web-final/Programs/seekh/seekh5.jpeg?updatedAt=1740412831369";
export const Logo =
  "https://ik.imagekit.io/bhavishya/web-final/logo%20Updates%20%20(1).png?updatedAt=1701111923138";
export const PrintMedia1 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/PrintMedia/PrintMedia1.png?updatedAt=1680110952740";
export const PrintMedia2 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/PrintMedia/PrintMedia2.png?updatedAt=1680110950343";
export const PrintMedia3 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/PrintMedia/PrintMedia4.png?updatedAt=1680110950203";
export const PrintMedia4 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/PrintMedia/PrintMedia3.png?updatedAt=1680110949861";
export const TimeLineSvg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/timeline.svg?updatedAt=1681583642913";
export const Kid1Img =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/Nirmal-Kid1.jpeg?updatedAt=1689880535416";
export const Kid2Img =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/Kids2.jpeg?updatedAt=1689705922750";

export const MeetOurTeamBg = "./MeetOurTeam1.png";
// Companies
export const Company1Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/netzsch.png?updatedAt=1682183919169";
export const Company2Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/taaza.png?updatedAt=1682183919123";
export const Company3Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/pinelabs.png?updatedAt=1682183918907";
export const Company4Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/opstree.png?updatedAt=1682183918730";
export const Company5Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/mobiforce.png?updatedAt=1682183918563";
export const Company6Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/AFCLogo.jpeg?updatedAt=1688400107267";
export const Company7Img = "https://ik.imagekit.io/bhavishya/web-final/companies/company7.png";
export const Company8Img = "https://ik.imagekit.io/bhavishya/web-final/companies/company8.png";
export const Company9Img = "https://ik.imagekit.io/bhavishya/web-final/companies/company9.svg";
// contact us svgs
export const partnerWithUsSvg =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/deal.svg?updatedAt=1682270210471";
export const sponsorAChildSvg =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/empathy.svg?updatedAt=1682270210462";
export const volunteerWithUsSvg =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/love.svg?updatedAt=1682270210441";

// test
export const testImageProject =
  "https://ik.imagekit.io/bhavishya/web-final/test/testimage.png?updatedAt=1682248669617";
export const testMapImage =
  "https://ik.imagekit.io/bhavishya/web-final/test/map.png?updatedAt=1682255753351";
export const testKidRameshImg =
  "https://ik.imagekit.io/bhavishya/web-final/test/testKidRamesh.png?updatedAt=1682324271100";

// Kid component
export const cloudSvg =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/Cloud.svg?updatedAt=1682323975304";

export const DoubleColumnData = [
  {
    title: "About Us",
    description: [
      "Bhavishya is a non - governmental organization that became officially registered in accordance with the indian trust act, 1882 in the year 2016.",
      "The organization was founded in 2016 and since then has been working to improve the lives of marginalized communities, with a particular emphasis on the youth of India, by providing them with access to high quality education at our educational facilities, which are spread across the country.",
      "In addition to providing educational opportunities, the organization also runs Skill Development and Vocational Training programs and Awareness campaigns to promote legal rights, equality, empowerment, health, hygiene, and prevention of exploitation, discrimination, and abuse.",
    ],
    img: "../../assets/LandingPage/about1.png",
    position: "left",
  },
  {
    title: "Mission",
    description: [
      "Providing quality education to underprivileged children, with access to quality teachers, educational resources, and an environment that encourages learning and exploration.",
      "Building strong partnerships with local organizations, governments, and businesses to provide the resources needed to ensure the development of the community. ",
      "Creating access to healthcare and providing medical care and support to children and their families. ",
      "Developing a holistic approach to poverty reduction and creating opportunities for sustainable livelihoods.",
      "Establishing a safe and secure environment for children and working to prevent abuse, exploitation, and discrimination.",
      "Our vision to holistically nurture and develop the youth, making them the pillars of society capable of leading INDIA"
      // "Developing innovative approaches to advocacy and campaigns to raise awareness of the needs of underprivileged children.",
      // "Establishing programs to help children develop skills and knowledge for their future.",
    ],
    position: "right",
  },

];

// export const ProgramsData = [
//   {
//     title: "Seekh Initiative",
//     description: [
//       "Join the Movement, Ignite Change! Get in touch today and become a part of our mission to create a better world. Together, we can make a difference!",
//     ],
//     img: "https://ik.imagekit.io/bhavishya/web-final/Programs/Seekh.jpeg?updatedAt=1689871797370",
//   },
//   {
//     title: "Shiksha Rickshaw",
//     description: [
//       "It is aimed at bringing education to underprivileged children living in poverty-stricken areas. An E-rickshaw equipped with a teacher and other educational resources reaches to the slum area.",
//     ],
//     img: "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw.jpeg?updatedAt=1689703139068",
//   },
//   {
//     title: "Vocational Training Center",
//     description: [
//       "The center offers stitching classes and teaches business skills. Women are equipped with skills to start their businesses or secure employment in the textile industry.",
//     ],
//     img: "https://ik.imagekit.io/bhavishya/web-final/Programs/Vocational_Training_Center.png?updatedAt=1689693988345",
//   },
//   {
//     title: "Old Age Home",
//     description: [
//       "Our latest project, an old age home for the poor and helpless. This initiative is currently under construction and will be located in the serene and picturesque city of Nainital. why we are committed to creating a welcoming and supportive environment for elderly individuals who have been left behind by society.",
//     ],
//     img: "https://ik.imagekit.io/bhavishya/web-final/Programs/oldage.jpeg?updatedAt=1689703066203",
//   },
//   {
//     title: "Computer Education Program",
//     description: [
//       "Bhavishya NGO's computer center is a dedicated initiative aimed at providing essential computer literacy to underprivileged children in the Noida area. The center offers a comprehensive curriculum, introducing students to the fundamentals of computers and their components.",
//     ],
//     img: "https://ik.imagekit.io/bhavishya/web-final/Programs/computer-education-program.jpeg?updatedAt=1689879878770",
//   },
//   {
//     title: "Sponsor A Kid Program",
//     description: [
//       "With our Sponsor a Kid Program, you can make a real difference in the life of a child by sponsoring their education for a minimal amount. Your donation will be used to provide the child with access to quality education in the nearby government school, books, stationery, uniforms, and other necessary resources.",
//     ],
//     img: "https://ik.imagekit.io/bhavishya/web-final/Programs/Sponser_a_kid.png?updatedAt=1689693991366",
//   },
// ];

export const ProgramsData = [
  {
    title: "Computer Shiksha Kendra",
    category: "Education",
    description:
      "According to data released by the NSO, only 15% of rural India has access to the internet and computers, creating a significant digital divide between urban and rural youth. To bridge this gap, Bhavishya has opened two computer training centers—one in Noida, UP, and the other in Madhubani, Bihar—where we provide free basic computer education to over 100 children.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/computer-education-program.jpeg?updatedAt=1689879878770",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/computershikshakedra/computerkedra1.jpeg?updatedAt=1739993088918",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/computershikshakedra/computerkedra2.jpeg?updatedAt=1739993088918",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/computershikshakedra/computerkedra3.jpeg?updatedAt=1739993088918",
    ],
  },
  {
    title: "Shiksha Rickshaw",
    category: "Education",
    description:
      "According to research, 43% of children living in slums between the ages of 6 and 14 have never enrolled in school, while 33% have dropped out due to various reasonsTo address this challenge, Bhavishya launched an innovative initiative called ‘Shiksha Rickshaw’ which brings education directly to the doorsteps of children in slums. So far, we have successfully taught over 200 children across 4 locations, These mobile classrooms allow us to reach kids where they live, ensuring they receive the education they deserve without the need to travel.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw/shiksharickshaw11.jpeg?updatedAt=1739991078770",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw/shiksharickshaw12.jpeg?updatedAt=1739991078770",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw/shiksharickshaw27.jpeg?updatedAt=1739991078770",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw/shiksharickshaw28.jpeg?updatedAt=1739991078770",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw/shiksharickshaw37.jpeg?updatedAt=1739991078770",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw/shiksharickshaw44.jpeg?updatedAt=1739991078770",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw/shiksharickshaw46.jpeg?updatedAt=1739991078770",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw/shiksharickshaw38.jpeg?updatedAt=1739991078770",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw/shiksharickshaw39.jpeg?updatedAt=1739991078770",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw/shiksharickshaw40.jpeg?updatedAt=1739991078770",
    ],
  },
  {
    title: "Education Center “SEEKH”",
    category: "Education",
    description:
      "For over 8 years, Bhavishya has been providing free education to more than 900 children through 12+ education centers. Along with regular classes, we guide and train students to help them get admission to nearby government and private schools.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/seekh/seekh1.jpeg?updatedAt=1740412831369",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/seekh/seekh2.jpeg?updatedAt=1740412831369",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/seekh/seekh3.jpeg?updatedAt=1740412831369",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/seekh/seekh4.jpeg?updatedAt=1740412831369",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/seekh/seekh5.jpeg?updatedAt=1740412831369",
    ],
  },
  {
    title: "Bhavishya Shiksha Kendra (Recognized School)",
    category: "Education",
    description:
      "Bhavishya has opened its own school, where over 100 children are enrolled for a minimal fee of just ₹10 a day. All books, stationery, and uniforms are provided free of charge by the NGO.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shikshakendra/bhavishyashikshakendra06.jpeg?updatedAt=1739020608483",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shikshakendra/bhavishyashikshakendra07.jpeg?updatedAt=1739020608483",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shikshakendra/bhavishyashikshakendra05.jpeg?updatedAt=1739020608483",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shikshakendra/bhavishyashikshakendra09.jpeg?updatedAt=1739020608483",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/shikshakendra/bhavishyashikshakendra03.jpeg?updatedAt=1739020608483",

    ],
  },
  {
    title: "Swasth Bhavishya",
    category: "Health",
    description:
      "Bhavishya regularly organizes health camps across various locations in India. So far, we have provided check-ups for 300+ children. Our health camps focus on the early detection of any illnesses, ensuring that if a child is diagnosed with any disease or health issues, they receive immediate follow-up care from medical specialists. This proactive approach helps in timely treatment, improving the chances of recovery and overall well-being.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/health/health1.jpeg?updatedAt=1740418355341",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/health/health2.jpeg?updatedAt=1740418355341",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/health/health3.jpeg?updatedAt=1740418355341",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/health/health5.jpeg?updatedAt=1740418355341",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/health/health6.jpeg?updatedAt=1740418355341",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/health/health7.jpeg?updatedAt=1740418355341",],
  },
  {
    title: "Aahar Sewa",
    category: "Health",
    description:
      "As per the National Family and Health Survey (2015–16), in India, about 38% of the children under the age of five year are stunted, 36% of the children are underweight, and 18% children are wasted due to low socio-economic status and lack of proper nutrition. To combat this nutritional deficiency among children coming from economically weaker backgrounds, Bhavishya has launched ‘Aahar Sewa Program’ where we feed 200+ children for free to provide them with a balanced diet and save them from chronic undernutrition.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/ahaar%20sewa/1718644559366.jpg?updatedAt=1735198221438",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/ahaar%20sewa/ahasrsewa3.jpeg?updatedAt=1739991726720",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/ahaar%20sewa/aharsewa4.jpeg?updatedAt=1739991726720",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/ahaar%20sewa/aharsewa2.jpeg?updatedAt=1739991726720",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/ahaar%20sewa/aharsewa5.jpeg?updatedAt=1739991726720",
    ],
  },
  {
    title: "Bhavishya Vocational Training Centre",
    category: "Livelihood",
    description:
      "We provide regular vocational training to non-working women, helping them gain the skills needed for self-employment. So far, we’ve successfully created job opportunities for over 100 women by teaching them to make cloth bags, ladies' dresses, and pickles. Support these women by purchasing their products",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/vocational%20traning/training14.jpeg?updatedAt=1739992554792",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/vocational%20traning/training13.jpeg?updatedAt=1739992554792",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/vocational%20traning/training15.jpeg?updatedAt=1739992554792",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/vocational%20traning/training2.jpeg?updatedAt=1739992554792",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/vocational%20traning/1729608100681.jpg?updatedAt=1735198506579",
    ],
  },
  {
    title: "Eco Bhavishya",
    category: "Environment",
    description:
      "We collect used newspapers, books, clothes, and other items from various locations. Our volunteers then sort the useful items out and distribute reusable materials to children for their day-to-day learning, promoting sustainability and resourcefulness.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/eco_bhavishya/eco8.jpeg?updatedAt=1739020177888",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/eco_bhavishya/eco10.jpeg?updatedAt=1739020177888",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/eco_bhavishya/eco11.jpeg?updatedAt=1739020177888",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/eco_bhavishya/eco9.jpeg?updatedAt=1739020177888",
    ],
  },
  {
    title: "Kabbad se Jugaad",
    category: "Environment",
    description:
      "This is a special training session for children to teach them the importance of recycling and creative utilization of waste by turning them into valuable resources like paintings, flowers, baskets, lamps, and decorative pieces which are available on our website for purchase.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kabad/kabad6.jpeg?updatedAt=1740477561818",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kabad/kabad3.jpeg?updatedAt=1740477561818",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kabad/kabad4.jpeg?updatedAt=1740477561818",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kabad/kabad2.jpeg?updatedAt=1740477561818",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kabad/kabad1.jpeg?updatedAt=1740477561818",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kabad/kabad5.jpeg?updatedAt=1740477561818",
    ],
  },
  {
    title: "Bhavishya - Kala Sangini",
    category: "Livelihood",
    description:
      "We actively promote local art forms such as Madhubani Art and Odisha Pattachitra created particularly by women, indigenous communities, and minorities on our website. This initiative is designed to financially support women artists and other marginalized communities, empowering them and fostering inclusivity through the celebration and promotion of traditional art.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kala/kala11.jpeg?updatedAt=1739992158886",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kala/kala12.jpeg?updatedAt=1739992158886",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kala/kala13.jpeg?updatedAt=1739992158886",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kala/kala14.jpeg?updatedAt=1739992158886",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kala/kala15.jpeg?updatedAt=1739992158886",
    ],
  },
  {
    title: "Bhavishya Deep Old Age Home",
    category: "Health",
    description:
      "Bhavishya Trust has built a 1,500 sq. ft. old age home, ‘Bhavishya Deep,’ in Dehradun for elderly individuals who have been abandoned by their families and have nowhere else to go. This home provides a peaceful environment for over 20 individuals to enjoy their later years. Nestled near the lush green forests of Corbett National Park, the facility offers 24/7 caretaking services.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/oldagehome/oldagehome10.jpeg?updatedAt=1739021267613",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/oldagehome/oldagehome12.jpeg?updatedAt=1739021267613",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/oldagehome/oldagehome9.jpeg?updatedAt=1739021267613",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/oldagehome/oldagehome6.jpeg?updatedAt=1739021267613",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/oldagehome/oldagehome1.jpeg?updatedAt=1739021267613",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/oldagehome/oldagehome7.jpeg?updatedAt=1739021267613",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/oldagehome/oldagehome4.jpeg?updatedAt=1739021267613",
    ],
  },
  {
    title: "Plantation Drive",
    category: "Environment",
    description:
      "Bhavishya NGO is dedicated to protecting the environment, and as part of our ongoing efforts, we collaborate with various organizations to conduct monthly plantation drives across different areas. Through this initiative, we aim to promote a greener and more sustainable future, while also raising awareness about the importance of environmental conservation.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/plantationdrive/plantation6.jpeg?updatedAt=1739986409459",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/plantationdrive/plantation7.jpeg?updatedAt=1739986409459",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/plantationdrive/plantation1.jpeg?updatedAt=1739986409459",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/plantationdrive/plantation5.jpeg?updatedAt=1739986409459",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/eco_bhavishya/eco2.jpeg?updatedAt=1739020177888",
    ],
  },
  {
    title: "Har Baste Me Kitab",
    category: "Environment",
    description:
      "Every March, Bhavishya NGO collects gently used books to distribute to underprivileged children. Alarmingly, 6 million Indian children lack access to education due to poverty (UNESCO) and 30% of government school students have no textbooks (ASER Report 2020).\n\n\n\nOur initiative promotes book recycling, fosters a love for learning, and bridges the literacy gap, empowering the next generation of leaders.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/harbastemekitab/kitab5.jpeg?updatedAt=1739987368696",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/harbastemekitab/kitab6.jpeg?updatedAt=1739987368683",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/harbastemekitab/kitab7.jpeg?updatedAt=1739987368683",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/harbastemekitab/kitab8.jpeg?updatedAt=1739987368683",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/harbastemekitab/kitab100.png?updatedAt=1740503399911"
    ],
  },
  {
    title: "Ujjwal Bhavishya",
    category: "Education",
    description:
      "Bhavishya NGO's 'Ujjwal Bhavishya' initiative empowers underprivileged communities through informative seminars and workshops. We invite esteemed speakers to inspire and motivate our target audience, comprising underprivileged children and women, providing them with the knowledge and confidence to shape a brighter future.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/ujjwalbhavishya/ujjwal4.jpeg?updatedAt=1739987969078",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/ujjwalbhavishya/ujjwal2.jpeg?updatedAt=1739987969078",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/ujjwalbhavishya/ujjwal5.jpeg?updatedAt=1739987969078",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/ujjwalbhavishya/ujjwal6.jpeg?updatedAt=1739987969078",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/ujjwalbhavishya/ujjwal7.jpeg?updatedAt=1739987969078",
    ],
  },
  {
    title: "Safe Women, One Pad",
    category: "Health",
    description:
      "Bhavishya NGO's initiative promotes menstrual health and hygiene among women in slum areas, providing free sanitary pads and awareness sessions. 70% of Indian women lack access to sanitary products (UNICEF), and 1 in 3 women suffer from reproductive tract infections due to poor menstrual hygiene (NFHS 2019-21).\n\n\n\nWe empower women to prioritize their health, bridging the gap in menstrual hygiene awareness and promoting a healthier life.",
    img: [
      "https://ik.imagekit.io/bhavishya/web-final/Campaigns/Onewomanpad.png?updatedAt=1689693909012",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/image2.jpeg?updatedAt=1740420842981",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/image1.jpeg?updatedAt=1740420842981"
    ],
  },
];


export const CampaignsData = [
  {
    title: "Har Baste Mein Kitaab",
    description: [
      "We provide bags of study materials to underprivileged children studying with us, ensuring that they do not lack the necessary tools they need to succeed.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Campaigns/har-baste-me-kitab.jpeg?updatedAt=1689871711825",
  },
  {
    title: "Safe Women One Pad",
    description: [
      "Our Safe Women One Pad initiative is a crucial step towards ensuring women's health and safety during their menstrual cycles. We distribute sanitary pads to women in the slums where it is not accessible.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Campaigns/Onewomanpad.png?updatedAt=1689693909012",
  },
  {
    title: "Ahaar Sewa Initiative",
    description: [
      "Our initiative is dedicated to ensuring no one goes hungry, with Ahaar Sewa we strive to provide nutritious meals to the students once a day, every day spreading smiles and nourishing lives. Together, let's combat hunger and make a positive impact on our community.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Campaigns/Ahaar_Sewa.png?updatedAt=1689693905936",
  },
  {
    title: "Paryavaran Shiksha",
    description: [
      "plantation drive In our impactful Plantation Drive initiative, we create a wave of environmental awareness.Through vibrant rallies, educational sessions, and meaningful tree plantations, we empower communities to become stewards of nature.Together, we inspire change, protect our precious ecosystem, and foster a greener, sustainable future for all.",
    ],
    img: "https://ik.imagekit.io/bhavishya/WhatsApp%20Image%202023-11-29%20at%2023.16.45.jpeg?updatedAt=1701280116190",
  },
  {
    title: "Winter Donation Drive",
    description: [
      "In India, many homeless families struggle to stay warm during the winter months. Winter Donation Drive provides warm clothing and blankets to those on the streets.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Campaigns/winter-donation-drive.jpeg?updatedAt=1689871711816",
  },
  {
    title: "Ujjwal Bhavishya Workshop",
    description: [
      "Our Ujjwal Bhavishya Workshop is designed to provide vocational training and other engaging programs to help children develop the skills they need to succeed in life. Different resource persons provide training in various skills.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Campaigns/UjjwalBhavishya.png?updatedAt=1689693906732",
  },
];

export const CenterDetails = [
  {
    id: 1,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/NoidaSector71-Center.jpeg?updatedAt=1689407241196",
    name: "Noida Sector 71",
    address: "B-125 Sector-71 Noida, Uttar Pradesh",
    description: "Address : B-125, Sector-71, Noida, Uttar Pradesh",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Noida, Uttar Pradesh",
    coordinates: [28.5948639, 77.3709966],
  },
  {
    id: 2,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/GreaterNoidaCenter.jpeg",
    name: "Noida Sector 119",
    address: "Noida, Uttar Pradesh",
    description: "Address : Community Centre, Sector 119, Noida, Uttar Pradesh",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Greater Noida, Uttar Pradesh",
    coordinates: [28.5881104, 77.399111],
  },
  {
    id: 3,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/VasantKunjCenter.jpeg",
    name: "Noida Sector 45",
    address: "Vasant Kunj, Delhi",
    description: "Address : Sadarpur Colony, Janta Public School, Gali No. 61, Sec- 45 Noida ",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Vasant Kunj, Delhi",
    coordinates: [28.5404, 77.1574],
  },
  {
    id: 4,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/FaridabadCenter.jpeg",
    name: "Moradabad Farrakhpur",
    address: "Thukurdwara, Farrakhpur, Moradabad, Uttar Pradesh",
    description: "Address : Vill- Farrakhpur, Tehsil- Thakurdwara, Moradabad, Uttar Pradesh",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Moradabad, Uttar Pradesh",
    coordinates: [28.5914035, 78.5415903],
  },
  {
    id: 5,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/GurugramCenter.jpeg",
    name: "Nainital Kotabagh Musabanger",
    address: "Nainital, Uttarakhand",
    description: "Address : Vill. Musabangar Bajuniyahaldu Kotabagh Nainital, Uttrakhand",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Vill. Musabangar Bajuniyahaldu Kotabagh Nainital, Uttrakhand",
    coordinates: [29.4025567, 79.2771268],
  },
  {
    id: 6,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/TauruCenter.jpeg",
    name: "Ramanagar Haripur Old Age Home",
    address: "Ramnagar, Uttarakhand",
    description: "Address : Vill. Haripur, Dhamola, Ramanager, Uttrakhand",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Ramnagar, Uttarakhand",
    coordinates: [29.3975127, 79.1031166],
  },
  {
    id: 7,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/MadhubaniCenter.jpeg",
    name: "Noida Sector 14A",
    address: "Noida, Uttar Pradesh",
    description: "Address : Sector 14A, Near Shani Mandir, Noida, Uttar Pradesh",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Sector 14A Near Shani Mandir, Noida, Uttar Pradesh",
    coordinates: [28.5829358, 77.302531],
  },
  {
    id: 8,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/BuxarCenter.jpeg",
    name: "West Bengal Thakurnagar Siliguri",
    address: "Siliguri, West Bengal",
    description: "Address : Thakurnagar, Siliguri West Bengal ",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Thakurnagar, Siliguri, West Bengal",
    coordinates: [26.7191927, 88.2753771],
  },
  {
    id: 9,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/BangaloreCenter.jpeg",
    name: "Bihar Madhubani Kothiya",
    address: "Vill. Kothiya, Madhubani, Bihar",
    description: "Address : Village Kothiya, Madhubani, Bihar",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Kothiya, Bihar",
    coordinates: [27.2895146, 78.7116802],
  },
  {
    id: 10,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/SiliguriCenter.jpeg",
    name: "Greater Noida Jalvayu Vihar",
    address: "Jalvayu Vihar, Greater Noida",
    description: "Address : Jalvayu Vihar, Greater Noida",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Jalvayu Vihar, Greater Noida",
    coordinates: [28.446343, 77.51273],
  },
  {
    id: 11,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/NainitalCenter.jpeg",
    name: "Bangalore Shanti Nagar",
    address: "Bangalore, Karnataka",
    description: "Address : KSRTC Coloney, Shanti Nagar, Bangalore ",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Bangalore, Karna",
    coordinates: [12.9560839, 77.5939876],
  },
  {
    id: 12,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/MoradabadCenter.jpeg",
    name: "Mobile Resources Center",
    address: "Tauru, Haryana",
    description: "Address : Tauru, Haryana",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Tauru, Harayana Pradesh",
    coordinates: [28.2103608, 76.9397611],
  },
  {
    id: 13,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/MoradabadCenter.jpeg",
    name: "Bhavishya Siksha Kedra",
    address: "Noida, Uttar Pradesh",
    description: "Address : Viil. Sarfabad, Sector 73, Noida, Uttar Pradesh",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Noida, Uttar Pradesh",
    coordinates: [28.5862257, 77.3766131],
  },
  {
    id: 14,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/MoradabadCenter.jpeg",
    name: "Branch : Mobile Resources Center",
    address: "Vasant Kunj, Delhi",
    description: "Address : Vasant Kunj, Delhi",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Vasant Kunj, Delhi",
    coordinates: [28.5267264, 77.1324247],
  },
  {
    id: 15,
    img: "https://ik.imagekit.io/bhavishya/web-final/Center/MoradabadCenter.jpeg",
    name: "Haryana Faridabad",
    address: "Faridabad, Haryana",
    description: "Address : Dheeraj Nagar Colony, Faridabad, Haryana",
    managedBy: "Managed By: Bhavishya NGO",
    fullAddress: "Gurugram, Uttar Pradesh",
    coordinates: [28.454194, 77.3213083],
  },
];

