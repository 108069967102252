import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const detailedStories = [
  {
    id: 1,
    title: "From Struggle to Success: Aditya's Journey With Us",
    content: "Aditya's life changed forever when he joined Bhavishya NGO 5 years ago. From seeking education to becoming a volunteer, Aditya's journey is a testament to resilience and determination.\n\nAfter losing his father during the pandemic, Aditya found solace in Bhavishya NGO. Their economic aid, mental support, and guidance helped him navigate the darkest moments. With the help of Bhavishya NGO, he secured admission to a government school and continued his education.\n\nToday, Aditya empowers underprivileged children as a proud volunteer at Bhavishya NGO. His story inspires us to pay it forward and make a difference.\n\n\n\n'Bhavishya NGO taught me to give back. I'm grateful for this journey,' says Aditya.",
    images: [
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story1.jpeg",
    //   "https://source.unsplash.com/500x300/?education",
    //   "https://source.unsplash.com/500x300/?volunteer"
    ],
    impact: "Aditya overcame financial hardships and personal loss with the support of Bhavishya NGO, securing education and transforming from a beneficiary to a dedicated volunteer. His journey highlights resilience, empowerment, and the positive impact of giving back to underprivileged communities."
  },
  {
    id: 2,
    title: "Bridging the Digital Divide: Madhubani Education Center",
    content: "Bhavishya NGO's Madhubani Education Centre, launched in 2021, provides free computer education to 200-300 underprivileged students. By equipping them with essential computer skills, including Tally, the centre has enabled students to secure better job opportunities. This initiative has democratized access to computer education in rural India, empowering students to compete in the modern job market.\n\nIn rural areas, especially in a place like Bihar, bridging the gap and providing accessibility to the latest equipment free of cost is a daunting task. However, at Bhavishya NGO, we are committed to making this a reality. We believe that every individual deserves access to quality education and resources, regardless of their geographical location or financial background.\n\nThe Madhubani Education Centre embodies Bhavishya NGO's commitment to empowering rural communities through education.",
    images: [
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story2a.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story2b.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story2c.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story2d.jpeg",
    ],
    impact: "The Madhubani Education Centre has empowered 200-300 underprivileged students by providing free computer education, including Tally, enhancing their employability. This initiative has bridged the digital divide in rural Bihar, ensuring access to modern skills and better job opportunities. Bhavishya NGO’s commitment to quality education and resource accessibility continues to uplift rural communities, fostering self-reliance and economic growth."
  },
  {
    id: 3,
    title: "Empowering Women: An Initiative",
    content: "Bhavishya NGO adopted Musabangar village in Nainital, Uttarakhand, and launched a skills development centre. This initiative empowered 30-40 local women to learn stitching, embroidery, and crafts.\n\n\n\nEmpowering rural women and contributing to community development, one village at a time.",
    images: [
     "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story3.jpeg"
    ],
    impact: "These women have transformed into entrepreneurs, creating and selling bags, clothes, and handicrafts. They've gained economic independence, enhanced skills, and a sense of self-worth."
  },
  {
    id: 4,
    title: "Empowering Female Artists: Kala Sangini Initiative",
    content: "Bhavishya NGO's Kala Sangini initiative in Madhubani, Bihar, has empowered 16-20 unemployed female artists to promote Mithila painting art.\n\n\n\nFostering artistic expression, promoting cultural heritage, and empowering women through sustainable livelihood opportunities.",
    images: [
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kala/kala11.jpeg?updatedAt=1739992158886",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kala/kala12.jpeg?updatedAt=1739992158886",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kala/kala13.jpeg?updatedAt=1739992158886",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kala/kala14.jpeg?updatedAt=1739992158886",
      "https://ik.imagekit.io/bhavishya/web-final/Programs/kala/kala15.jpeg?updatedAt=1739992158886",
    ],
    impact: "- Provided a livelihood opportunity to talented artists\n\n- Preserved and promoted the traditional Mithila painting art form\n\n- Enhanced the economic independence and confidence of female artists."
  },
  {
    id: 5,
    title: "Bhavishya NGO's COVID-19 Relief Efforts: A Success Story",
    content: "In the midst of the COVID-19 pandemic, Bhavishya NGO joined forces with the Gautam Buddha Nagar Police Commissionerate to provide essential relief to those in need. This collaborative effort resulted in the distribution of ration to over 6,000 families in Noida's Phase 3 area.\n\nUnder the guidance of DCP S. Rajesh, whose leadership was instrumental in the success of this initiative, the team worked tirelessly to ensure that the ration reached the most vulnerable members of the community. The impact of this effort was immense, providing sustenance and hope to those who needed it most during a time of great uncertainty.\n\nThis success story highlights the power of collaboration and community spirit in the face of adversity. Bhavishya NGO's commitment to serving the community, combined with the support of the Gautam Buddha Nagar Police Commissionerate, made a tangible difference in the lives of thousands of individuals.",
    images: [
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5a.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5b.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5c.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5d.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5e.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5f.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5g.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5h.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5i.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5j.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5k.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5l.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5m.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5n.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5o.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5p.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5q.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5r.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5s.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5t.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5u.jpeg",
    ],
    impact: "Bhavishya NGO's collaboration with the Gautam Buddha Nagar Police Commissionerate provided vital relief to over 6,000 families in Noida’s Phase 3 during the COVID-19 pandemic. This initiative ensured that vulnerable communities received essential rations, offering hope and sustenance in a time of uncertainty. The partnership highlighted the power of teamwork and compassion, with Bhavishya NGO and the Police Commissionerate making a tangible difference in the lives of thousands, demonstrating the impact of community spirit in times of crisis."
  },
  {
    id: 6,
    title: "Bhavishya NGO's 'Sab Padhe Sab Badhe' Initiative",
    content: "In 2022, Bhavishya NGO partnered with the Gautam Buddha Nagar Police Commissionerate to launch 'Sab Padhe Sab Badhe' in Noida's Sector 14(A). Under DCP Vrinda Shukla's guidance, an education centre was set up in a slum area, offering awareness activities and workshops.\n\nToday, 40 students attend classes regularly, showing remarkable academic progress. This collaborative effort demonstrates the transformative power of education, empowering marginalized communities and fostering a brighter future.\n\nBhavishya NGO remains committed to expanding this initiative, reaching more children and promoting education for all.",
    images: [
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story6.jpeg"
    ],
    impact: "The 'Sab Padhe Sab Badhe' initiative, launched by Bhavishya NGO in partnership with the Gautam Buddha Nagar Police Commissionerate, has made a significant impact by providing education to marginalized children in Noida’s Sector 14(A). The education center has become a beacon of hope, with 40 students regularly attending classes and showing remarkable academic progress. This effort showcases the transformative power of education in breaking the cycle of poverty and empowering the community. Bhavishya NGO's ongoing commitment to expanding the initiative continues to uplift more children, creating a brighter and more equitable future."
  },
  {
    id: 7,
    title: "Bhavishya NGO: A Beacon of Hope",
    content: "In 2013, Shanti Gupta, a devoted mother, was forced to discontinue her children's education due to financial constraints. Her husband's illness had left the family struggling, and Shanti's meager earnings from a tea stall barely covered basic needs.\n\nIn 2018, Bhavishya NGO intervened, facilitating the admission of Jyoti, Kumkum, and Ashish to a reputable school and covering their tuition fees. This act of kindness paved the way for the siblings to rediscover their passion for learning.\n\nToday, all three siblings are gainfully employed, earning a steady income and supporting their family. Shanti's sacrifices have finally borne fruit, and her children's success has brought immense pride and joy. Bhavishya NGO's timely intervention has transformed this family's life, showcasing the power of compassion and community support.",
    images: [
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story7a.jpeg",
      "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story7b.jpeg"
    ],
    impact: "Bhavishya NGO's timely intervention in 2018 had a profound impact on the Gupta family. By facilitating the admission of Jyoti, Kumkum, and Ashish into a reputable school and covering their tuition fees, the NGO enabled the siblings to regain their educational path. Today, all three are gainfully employed and supporting their family. The success of these children, who once faced significant challenges, is a testament to the transformative power of education and community support. Bhavishya NGO’s compassion has turned their lives around, showing the lasting impact of timely intervention in creating brighter futures."
  }
];

const SuccessStoryDetail = () => {
  const { id } = useParams();
  const story = detailedStories.find((story) => story.id === parseInt(id));

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (story && story.images && story.images.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % story.images.length); // Simplified modulo for looping
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [story]);

  if (!story) {
    return <div className="text-center text-xl font-semibold mt-10">Story not found!</div>;
  }

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? story.images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % story.images.length); // Simplified modulo for looping
  };

  return (
    <div className="bg-white min-h-screen p-6 md:p-10">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-6">{story.title}</h1>

        {/* Image Slider with Fixed Dimensions and Conditional Rendering */}
        <div className="relative w-full mb-6 rounded-lg overflow-hidden" style={{ height: '500px' }}>
          {story.images && story.images.length > 0 ? ( // Check if images exist
            <>
              <img
                src={story.images[currentImageIndex]}
                alt={`Slide ${currentImageIndex + 1}`}
                className="w-full h-full object-cover rounded-lg transition-transform duration-300"
              />
              <div className="absolute inset-0 flex justify-between items-center"> {/* Navigation container */}
                <button onClick={handlePrev} className="bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75">❮</button>
                <button onClick={handleNext} className="bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75">❯</button>
              </div>
              <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {story.images.map((_, index) => (
                  <button
                    key={index}
                    className={`w-3 h-3 rounded-full ${index === currentImageIndex ? "bg-white" : "bg-gray-400"} transition-all`}
                    onClick={() => setCurrentImageIndex(index)}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="absolute inset-0 flex items-center justify-center text-gray-500">No images available</div> // Message if no images
          )}
        </div>


        <h2 className="text-2xl font-semibold mt-6 mb-2">Overview</h2>
        <div className="text-lg mb-6">
          {story.content.split("\n").map((paragraph, index) => (
            paragraph.trim() && <p key={index} className="mb-4">{paragraph}</p>
          ))}
        </div>

        {story.impact && (
          <>
            <h2 className="text-2xl font-semibold mt-6 mb-2">Impact</h2>
            <div className="text-md">
              {story.impact.split("\n").map((paragraph, index) => (
                paragraph.trim() && <p key={index} className="mb-4">{paragraph}</p>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SuccessStoryDetail;