import React from "react";
import { Link } from "react-router-dom";

const TeamCard = ({ name, designation, img, page, department }) => {
  return (
    <Link to="/team" className="block">
      <div className={`relative w-60 text-center mx-auto rounded cursor-pointer ${page !== 'team' ? 'bg-borderRed' : ''}`}
        style={{ height: "75px" }}>
        {/* Outer Border */}
        <div className="border-red-500 rounded-md p-4 flex items-center justify-center h-full">
          {/* Image */}
          {/* <img
            src={img}
            alt={name}
            className="rounded-md object-cover"
            style={{ height: "100px", width: "100px" }}
          /> */}
          {/* Name */}
          <h2 className={`font-bold ${page !== 'team' ? 'text-white' : 'text-black text-2xl'}`}>
            {name}
          </h2>
          {/* Designation */}
          {/* <p className="text-sm text-gray-500">{designation}</p> */}
          {/* Department */}
          {/* <p className="text-xs text-gray-400">{department}</p> */}
        </div>
      </div>
    </Link>
  );
};

const teamMembers = [
  { id: 1, name: "Vikash Jha", designation: "Designation", img: "", department: "Department" },
  { id: 2, name: "Rahul Pandey", designation: "Designation", img: "" },
  { id: 3, name: "Nitish Bansal", designation: "Designation", img: "" },
  { id: 4, name: "Harpreet Aswal", designation: "Designation", img: "" },
  { id: 5, name: "Bidhu Mishra", designation: "Designation", img: "" },
  { id: 6, name: "Sanjay Kaushik", designation: "Designation", img: "" },
  { id: 7, name: "Deepak yadav", designation: "Designation", img: "", department: "Department" },
  { id: 8, name: "Maninder Sharma", designation: "Designation", img: "", department: "Department" },
  { id: 9, name: "Deepa Masiwal", designation: "Designation", img: "", department: "Department" },
  { id: 10, name: "Shalu Jha", designation: "Designation", img: "", department: "Department" },
  { id: 11, name: "Om Singh", designation: "Designation", img: "" },
  { id: 12, name: "Vedhika Yadvi", designation: "Designation", img: "" },
  { id: 13, name: "Ravikant Yadav", designation: "Designation", img: "" },
  { id: 14, name: "Rohan Sharma", designation: "Designation", img: "" },
  { id: 15, name: "Neha Verma", designation: "Designation", img: "" },
  { id: 16, name: "Pankaj Pandey", designation: "Designation", img: "" },
  { id: 17, name: "Mukesh Yadav", designation: "Designation", img: "" },
  { id: 18, name: "Pallav Bishwas", designation: "Designation", img: "" },
  { id: 19, name: "Lakshmi Shetty", designation: "Designation", img: "" },
  { id: 20, name: "Aman", designation: "Designation", img: "" },
  { id: 21, name: "Ajay Yadav", designation: "Designation", img: "" },
  { id: 22, name: "Suraj", designation: "Designation", img: "" },
  { id: 23, name: "Agrani Dwivedi", designation: "Designation", img: "" },
  { id: 24, name: "Arun", designation: "Designation", img: "" },
];

const MeetOurTeam = ({ heading, isFirstRow, page }) => {
  const firstRow = teamMembers.slice(0, 4);
  const otherRows = teamMembers.slice(4);

  return (
    <div className="bg-cream min-h-screen py-10">
      {heading && <h1 className="text-center text-4xl font-bold mb-10">{heading}</h1>}
      <div className="space-y-10 max-w-7xl mx-auto px-4">
        {isFirstRow && (
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 justify-center">
            {firstRow.map((member) => (
              <TeamCard key={member.id} name={member.name} designation={member.designation} img={member.img} department={member.department} page={page} />
            ))}
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 justify-center">
          {otherRows.map((member) => (
            <TeamCard key={member.id} name={member.name} designation={member.designation} img={member.img} department={member.department} page={page} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MeetOurTeam;
