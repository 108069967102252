import { Route, Routes } from "react-router-dom";
import { DonateSection, EdSupportTab, Footer, Navbar } from "./components";
import {
  Campaigns,
  Center,
  Contact,
  LandingPage,
  MediaPage,
  Programs,
} from "./pages";
import About from "./pages/About";
import Team from "./pages/Team";
import CentersPage from "./pages/OurCenter";
import ProgramDetail from "./pages/ProgramDetail";
import SuccessStory from "./pages/sucessstory";
import SuccessStoryDetail from "./pages/successstorydetail";
import AboutUs from "./components/LandingPage/aboutus";

function App() {
  return (
    <div className="">
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/donate" element={<DonateSection />} />
        <Route path="/seekh" element={<EdSupportTab />} />
        <Route path="/media" element={<MediaPage />} />
        <Route path="/centers" element={<Center />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/ourcenters" element={<CentersPage/>} />
        <Route path="/successstory" element={<SuccessStory/>}/>
        <Route path="/story/:id" element={<SuccessStoryDetail />} /> 
        <Route path="/team" element={<Team />} /> 
        <Route path="/program/:programId" element={<ProgramDetail/>} />
        <Route path="/aboutus" element={<AboutUs/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
