// import React, { useEffect } from "react";
import { DoubleColumn } from "../components";
import { About1Bg, About2Bg, About3Bg, DoubleColumnData } from "../assets";
// import Center from "./Center";
import MeetOurTeam from "./MeetOurTeam";

export default function About() {
  const DoubleColumnImgs = [About1Bg, About2Bg, About3Bg];
  const position = ["left", "right", "left"];
  const DoubleColumnColors = ["bg-[#FEF7ED]", "bg-[#FEF7ED]", "bg-[#FEF7ED]"];

  return (
    <>
      <div className="">
        {DoubleColumnData.map((item, i) => (
          <div key={item.title}>
            <DoubleColumn
              title={item.title}
              description={item.description}
              img={DoubleColumnImgs[i]}
              position={position[i]}
              clrClass={DoubleColumnColors[i]}
            />
          </div>
        ))}
      </div>

      {/* <Center cardsToDisplay={3} /> */}
      <div className="text-center bg-[#FEF7ED]">
        {/* <button
          onClick={() => (window.location.href = "/ourcenters")}
          className="text-black font-bold px-6 py-3 underline hover:underline transition duration-300"
        >
          View All Centers
        </button> */}

        <MeetOurTeam heading={"Meet Our Team"}  isFirstRow="true"/>
      </div>
    </>
  );
}
