import React from "react";
import { CenterDetails } from "../assets";

const Center = ({ cardsToDisplay }) => {
  return (
    <div>
      <div class="bg-primaryBg mb-10">
        <div class=" font-bold py-8 text-4xl md:text-6xl	text-black text-center font-poppins">
          Our Centres
        </div>

        <div className="mx-10 md:mx-16 space-y-10 lg:space-y-0 grid grid-cols-1 lg:grid-cols-3 lg:gap-10">
          {CenterDetails.slice(0, cardsToDisplay || CenterDetails.length).map(
            (item, i) => (
              <div
                className="text-left shadow-xl bg-borderRed rounded-lg overflow-hidden p-4" 
                key={i}
              >
                <div className="overflow-hidden">
                  <img
                    src={item.img}
                    className="w-full h-72 object-cover rounded-t-lg"
                    alt={item.title}
                  />
                </div>
                <div className="p-4">
                  <p className="text-xl md:text-2xl font-bold font-Poopins text-white">
                    {item.address}
                  </p>
                  <p className="text-sm md:text-lg font-Poopins text-white mt-2">
                    {item.managedBy}
                  </p>
                  <p className="text-sm md:text-lg font-Poopins text-white mt-2">
                    {item.fullAddress}
                  </p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Center;
