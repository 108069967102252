import React from "react";
// import MeetOurTeam from "./MeetOurTeam";

const TeamMember = ({
  name,
  designation,
  img,
  description,
  paragraph1,
  paragraph2,
  paragraph3,
  paragraph4,
  paragraph5,
  paragraph6,
  paragraph7,
  phone,
  email,
  reverse,
}) => {
  return (
    <div
      className={`flex flex-col md:flex-row ${reverse ? "md:flex-row-reverse" : ""
        } items-center md:items-start`}
      style={{ display: "flex", width: "100%" }}
    >
      {/* Image Section */}
      <div
        className="flex flex-col items-center justify-center"
        style={{ flexBasis: "50%", textAlign: "center" }}
      >
        <div className="relative border-4 border-red-500 rounded-md overflow-hidden mb-4">
          <img
            src={img}
            alt={name}
            className="object-cover rounded-md"
            style={{ width: "300px", height: "350px" }}
          />
        </div>
        <h2
          className="text-5xl font-bold text-gray-800 mt-2"
          style={{
            fontFamily: '"Praise", cursive',
            fontWeight: 400,
            fontStyle: "normal",
          }}
        >
          {name}
        </h2>
        <p className="text-lg text-gray-600">{designation}</p>
      </div>

      {/* Text Section */}
      <div
        className="text-center md:text-left"
        style={{
          flexBasis: "50%",
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%", // Ensures the container spans the full height for distribution
        }}
      >
        <p className="text-gray-700 leading-relaxed text-xl tracking-wider font-light">{paragraph1}</p>
        <br/>
        <p className="text-gray-700 leading-relaxed text-xl tracking-wider font-light">{paragraph2}</p><br/>
        <p className="text-gray-700 leading-relaxed text-xl tracking-wider font-light">{paragraph3}</p><br/>
        <p className="text-gray-700 leading-relaxed text-xl tracking-wider font-light">{paragraph4}</p><br/>
        <p className="text-gray-700 leading-relaxed text-xl tracking-wider font-light">{paragraph5}</p><br/>
        <p className="text-gray-700 leading-relaxed text-xl tracking-wider font-light">{paragraph6}</p><br/>
        <p className="text-gray-700 leading-relaxed text-xl tracking-wider font-light">{paragraph7}</p>

        {/* Contact Details */}
        <div
          className="mt-6 flex items-center justify-center md:justify-start space-x-6 font-bold text-lg"
          style={{ gap: "100px" }} // Adds spacing between the phone and email
        >
          <p className="text-gray-800 flex items-center">
            <span className="mr-2">📞</span> {phone}
          </p>
        </div>
      </div>
    </div>
  );
};

const Team = () => {
  const teamMembers = [
    {
      id: 1,
      name: "Vikash Jha",
      designation: "Founder and President",
      img: "https://ik.imagekit.io/bhavishya/web-final/Screenshot%202025-02-08%20at%207.02.45%20PM.png?updatedAt=1739021661964", // Replace with actual image URL
      paragraph1:
        "As we celebrate a decade of service and impact, I am filled with gratitude and pride for what we have accomplished together. Ten years ago, we embarked on a journey fueled by a simple yet powerful vision: to uplift underprivileged communities through education, skill development, and empowerment.",
      paragraph2: "Our commitment to children's education remains at the heart of our mission. From ensuring access to quality education to facilitating school enrollment, we have witnessed firsthand the transformative power of knowledge. Every child we have reached is a testament to the potential that exists within our communities.",
      paragraph3: "Recognizing the evolving needs of our youth, we expanded our efforts to establish skill development centers. These hubs of learning have equipped countless young individuals with practical skills, enabling them to pursue meaningful careers and break the cycle of poverty. Our commitment to youth empowerment is unwavering, and we are dedicated to channeling their energy towards positive change.",
      paragraph4: "In recent years, we have taken significant strides in empowering women to earn their livelihoods. We believe that when women thrive, entire communities flourish. By providing training, resources, and support, we are helping women build sustainable futures for themselves and their families.",
      paragraph5: "Our work is grounded in the belief that true change comes from understanding the unique challenges faced by those we serve. We are proud to be a grassroots organization, committed to listening, learning, and adapting to the needs of the community. By harnessing technology and innovative solutions, we are working to tackle pressing issues and create lasting impact.",
      paragraph6: "I would also like to extend an invitation to young individuals from schools and colleges to join us in our mission. Your passion and fresh perspectives are invaluable in driving social change. Together, we can inspire a new generation of leaders dedicated to making a difference.",
      paragraph7: "As we look to the future, we remain steadfast in our commitment to creating a brighter, more equitable world. Thank you for your continued support, belief, and partnership. Together, we will keep striving for a society where every individual has the opportunity to thrive.",
      phone: "+91 96544 60578",
    },
  ];

  return (
    <div className="bg-primaryBg min-h-screen py-10 px-4">
      {/* Title */}
      <h1 className="text-center text-4xl font-bold text-red-600 mb-16">
        Meet Our Team
      </h1>

      {/* Team Members */}
      <div className="space-y-16 max-w-7xl mx-auto">
        {teamMembers.map((member, index) => (
          <TeamMember
            key={member.id}
            name={member.name}
            designation={member.designation}
            paragraph1 ={member.paragraph1}
            paragraph2 ={member.paragraph2}
            paragraph3 ={member.paragraph3}
            paragraph4 ={member.paragraph4}
            paragraph5 ={member.paragraph5}
            paragraph6 ={member.paragraph6}
            paragraph7 ={member.paragraph7}
            img={member.img}
            description={member.description}
            phone={member.phone}
            // email={member.email}
            reverse={index % 2 !== 0} // Alternate alignment
          />
        ))}
      </div>
      <br />
      {/* <div className="bg-secondaryBg p-4 pt-4 md:p-8 lg:p-6">
        <h1 className="text-center text-4xl font-bold text-red-600 text-white">
          Management Team
        </h1>
      </div>
      <p className="text-center text-black text-lg mt-10 mr-10 ml-10"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad esse quo veniam aspernatur, repellendus delectus ratione, repudiandae animi facilis quod cum blanditiis optio minus recusandae neque veritatis ut odit tenetur?</p>


      <MeetOurTeam isFirstRow="true" page="team" />
      <div className="bg-secondaryBg p-4 pt-4 md:p-8 lg:p-6">
        <h1 className="text-center text-4xl font-bold text-red-600 text-white">
          Volunteer Team
        </h1>        </div>

      <MeetOurTeam page="team" /> */}
    </div>
  );
};

export default Team;
