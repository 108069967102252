// import React, { useState } from "react";
// import { GiCancel } from "react-icons/gi";
// import { cloudSvg, Kid1Img, Kid2Img } from "../../assets";
// import { Link } from "react-router-dom";

// const KidStoryComponent = ({ kidImg, name, setShowKidStory, showKidStory }) => {
//   return (
//     <div className="relative">
//       <div className="w-full md:flex md:items-center md:justify-center">
//         <img
//           src={kidImg}
//           alt={name}
//           className="lg:h-[750px] lg:w-[500px] lg:object-cover"
//         />
//       </div>
//       <div className="absolute text-white font-Poppins font-bold bottom-[10%] px-12 left-6 md:left-28 lg:left-10 text-start space-y-4">
//         <div className="text-4xl md:text-7xl font-Cheri text-secondaryBg drop-shadow-[0_1px_1px_rgba(256,256,256,1)]">
//           {name}
//         </div>
//         {!showKidStory && (
//           <button
//             onClick={() => setShowKidStory(!showKidStory)}
//             className="rounded font-Cheri text-white border-buttonBg border-2  hover:bg-white hover:text-secondaryBg bg-secondaryBg  px-4 py-2 text-2xl hidden lg:flex">
//             Read {name}'s story
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// const SingleKidStoryComponent = ({
//   kidImg,
//   name,
//   description,
//   setShowKidStory,
//   showKidStory,
// }) => {
//   return (
//     <div>
//       {/* <KidStoryComponent kidImg={kidImg} name={name} showKidStory={showFunc} /> */}
//       <div className="flex flex-col h-cover space-y-4 lg:space-y-16">
//         <div className="hidden lg:flex items-center justify-end">
//           <GiCancel
//             onClick={() => setShowKidStory(!showKidStory)}
//             className="scale-[200%] fill-secondaryBg cursor-pointer hover:fill-black m-2 rounded-lg"
//           />
//         </div>
//         <div className="text-sm md:text-lg lg:text-xl text-justify">
//           {description}
//         </div>
//         <Link to="/donate">
//           <div className="flex items-center justify-around lg:justify-end">
//             <button className="rounded font-Cheri text-white border-buttonBg border-2  hover:bg-white hover:text-secondaryBg bg-secondaryBg  px-4 py-2 text-xl  md:text-2xl ">
//               I want to help {name}
//             </button>
//           </div>
//         </Link>
//       </div>
//     </div>
//   );
// };

// const KidsStory = () => {
//   const [showKid1Story, setShowKid1Story] = useState(false);
//   const [showKid2Story, setShowKid2Story] = useState(false);
//   return (
//     <div className="bg-primaryBg p-2 md:p-6 lg:p-12 lg:relative">
//       <div className="flex flex-col items-center justify-center lg:py-12 lg:mx-8  bg-white p-12 lg:px-24 border-2 rounded-2xl  space-y-8 lg:space-y-20 relative">
//         <div className="font-Cheri text-4xl md:text-6xl lg:text-8xl text-secondaryBg">
//           Meet our kids.
//         </div>
//         <div className="hidden lg:flex space-x-12 w-full lg:items-center lg:justify-center">
//           {!showKid1Story && !showKid2Story && (
//             <>
//               <KidStoryComponent
//                 kidImg={Kid1Img}
//                 name="Nirmal"
//                 setShowKidStory={setShowKid1Story}
//                 showKidStory={showKid1Story}
//               />
//               <KidStoryComponent
//                 kidImg={Kid2Img}
//                 name="Kumkum, Jyoti & Ashish"
//                 setShowKidStory={setShowKid2Story}
//                 showKidStory={showKid2Story}
//               />
//             </>
//           )}

//           {showKid1Story && !showKid2Story && (
//             <>
//               <KidStoryComponent
//                 kidImg={Kid1Img}
//                 name="Nirmal"
//                 setShowKidStory={setShowKid1Story}
//                 showKidStory={showKid1Story}
//               />
//               <div className="w-1/2">
//                 <SingleKidStoryComponent
//                   kidImg={Kid1Img}
//                   name="Nirmal"
//                   description="This is Nirmal. Nirmal Kanojia. He is 17 years old now. Due to unfortunate events , he lost his father at a very young age. Since then he has been a student in bhavishya . Also he is one of the very first kids that bhavishya ngo adopted as a student. Recently Nirmal completed his 10th board exams and is now waiting for obvious good grades in his result. From the very beginning every volunteer in the ngo took part to help Nirmal in his studies. Some of our volunteers were working back then and still managed to come to the ngo. Nirmal also received  sponsorship money on monthly basis for his schooling requirements through the ngo and its volunteers. Nirmal's mother is a housewife and is single-handedly managing the house which was and is very difficult for them , so we as a ngo try to help them whenever it is possible through whatever sources. We started our ngo classes in the green belts of sec-71 and now we have more than 9 branches with proper rooms,books and teachers to guide and help the unprivileged children in their studies. Along with Nirmal now we have more than 150 students under our ngo, learning, growing and excelling in their classes."
//                   setShowKidStory={setShowKid1Story}
//                   showKidStory={showKid1Story}
//                 />
//               </div>
//             </>
//           )}

//           {showKid2Story && !showKid1Story && (
//             <>
//               <div className="w-1/2">
//                 <SingleKidStoryComponent
//                   kidImg={Kid2Img}
//                   name="Kumkum, Jyoti & Ashish"
//                   description="Three siblings Kumkum , Jyoti & Ashish were dropouts & didn't went to school from last three years when they came in touch with Bhavishya ngo in 2018 . Mother runs small tea shop & Father is not well & mostly on bed. Apart from that tea shop there is no source of income for this family. We brought them to our education center & from 2018 to 2019 we gave them all the support so that they become capable for getting admission in class 8th . In 2019 all of them got admission in class 8th. In 2022 all of them scored good marks in board exam. Currently they are in 12th standard hope they will overcome all these difficulties & bring happiness to their family. In between all these year we supported them financially so that they can continue their studies."
//                   setShowKidStory={setShowKid2Story}
//                   showKidStory={showKid2Story}
//                 />
//               </div>
//               <KidStoryComponent
//                 kidImg={Kid2Img}
//                 name="Kumkum, Jyoti & Ashish"
//                 setShowKidStory={setShowKid2Story}
//                 showKidStory={showKid2Story}
//               />
//             </>
//           )}
//         </div>
//         <div className="flex flex-col lg:hidden  w-full space-y-8">
//           <>
//             <KidStoryComponent
//               kidImg={Kid1Img}
//               name="Nirmal"
//               setShowKidStory={setShowKid1Story}
//               showKidStory={showKid1Story}
//             />
//             <SingleKidStoryComponent
//               kidImg={Kid1Img}
//               name="Nirmal"
//               description="This is Nirmal. Nirmal Kanojia. He is 17 years old now. Due to unfortunate events , he lost his father at a very young age. Since then he has been a student in bhavishya . Also he is one of the very first kids that bhavishya ngo adopted as a student. Recently Nirmal completed his 10th board exams and is now waiting for obvious good grades in his result. From the very beginning every volunteer in the ngo took part to help Nirmal in his studies. Some of our volunteers were working back then and still managed to come to the ngo. Nirmal also received  sponsorship money on monthly basis for his schooling requirements through the ngo and its volunteers. Nirmal's mother is a housewife and is single-handedly managing the house which was and is very difficult for them , so we as a ngo try to help them whenever it is possible through whatever sources. We started our ngo classes in the green belts of sec-71 and now we have more than 9 branches with proper rooms,books and teachers to guide and help the unprivileged children in their studies. Along with Nirmal now we have more than 150 students under our ngo, learning, growing and excelling in their classes."
//               setShowKidStory={setShowKid1Story}
//               showKidStory={showKid1Story}
//             />
//           </>
//           <>
//             <KidStoryComponent
//               kidImg={Kid2Img}
//               name="Kumkum, Jyoti & Ashish"
//               setShowKidStory={setShowKid2Story}
//               showKidStory={showKid2Story}
//             />
//             <SingleKidStoryComponent
//               kidImg={Kid2Img}
//               name="Kumkum, Jyoti & Ashish"
//               description="Three siblings Kumkum , Jyoti & Ashish were dropouts & didn't went to school from last three years when they came in touch with Bhavishya ngo in 2018 . Mother runs small tea shop & Father is not well & mostly on bed. Apart from that tea shop there is no source of income for this family. We brought them to our education center & from 2018 to 2019 we gave them all the support so that they become capable for getting admission in class 8th . In 2019 all of them got admission in class 8th. In 2022 all of them scored good marks in board exam. Currently they are in 12th standard hope they will overcome all these difficulties & bring happiness to their family. In between all these year we supported them financially so that they can continue their studies."
//               setShowKidStory={setShowKid2Story}
//               showKidStory={showKid2Story}
//             />
//           </>
//         </div>
//       </div>

//       {/* <div className="hidden lg:flex lg:absolute lg:left-1/3 lg:bottom-[2%]">
//         <img src={cloudSvg} alt="Cloud SVG" className="scale-75 " />
//       </div> */}
//       <div className="hidden lg:flex lg:absolute lg:right-0 lg:top-[2%]">
//         <img src={cloudSvg} alt="Cloud SVG" className="scale-75 " />
//       </div>
//     </div>
//   );
// };

// export default KidsStory;
import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const stories = [
  { 
    id: 1, 
    name: "From Struggle to Success: Aditya's Journey With Us", 
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story1.jpeg" 
    },
  { 
    id: 2, 
    name: "Bridging the Digital Divide : Madhubani Education Center", 
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story2a.jpeg" 
    },
  { 
    id: 3, 
    name: "Empowering Women : An Initiative", 
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story3.jpeg" },
  { 
    id: 4, 
    name: "Empowering Female Artists: Kala Sangini Initiative", 
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story4a.jpeg"
    },
  { 
    id: 5, 
    name: "Bhavishya NGO's COVID-19 Relief Efforts: A Success Story", 
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5a.jpeg"
    },
  { 
    id: 6, 
    name: "Bhavishya NGO's 'Sab Padhe Sab Badhe' Initiative: Empowering Education in Noida", 
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story6.jpeg"
    },
  { 
    id: 7, 
    name: "Bhavishya NGO: A Beacon of Hope", 
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story7a.jpeg"
    }
];

const SuccessStory = () => {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 300;
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 300;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollLeft += 300;
        if (scrollRef.current.scrollLeft >= scrollRef.current.scrollWidth - scrollRef.current.clientWidth) {
          scrollRef.current.scrollLeft = 0;
        }
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-white p-10 w-full">
      <h1 className="text-4xl font-bold text-center mb-8">Turning Challenges into Achievements</h1>
      <div className="relative w-full flex items-center">
        <button 
          onClick={scrollLeft} 
          className="absolute left-0 z-10 bg-[#EB5A57] text-white p-3 rounded-full shadow-lg hover:bg-[#d94f4c] transition"
        >
          ◀
        </button>
        <div
          ref={scrollRef}
          className="flex space-x-6 overflow-hidden px-4 w-full"
          style={{ display: 'flex', gap: '1rem', scrollBehavior: 'smooth', whiteSpace: 'nowrap' }}
        >
          {stories.map((story) => (
            <div
              key={story.id}
              className="card w-72 flex-shrink-0 rounded-lg overflow-hidden shadow-lg bg-white h-96"
            >
              <img
                src={story.img}
                alt={story.name}
                className="card-img-top w-full h-60 object-cover"
              />
              <div className="card-body p-4 bg-[#EB5A57] text-white h-36 flex flex-col justify-between">
                <p 
                  className="card-text text-lg font-bold whitespace-normal overflow-hidden text-ellipsis"
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2, // Restrict to 2 lines
                  }}
                >
                  {story.name}
                </p>
                <Link
                  to={`/story/${story.id}`}
                  className="inline-block bg-white text-[#EB5A57] font-semibold py-2 px-4 rounded-lg hover:bg-gray-200 transition duration-300"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
        <button 
          onClick={scrollRight} 
          className="absolute right-0 z-10 bg-[#EB5A57] text-white p-3 rounded-full shadow-lg hover:bg-[#d94f4c] transition"
        >
          ▶
        </button>
      </div>
    </div>
  );
};

export default SuccessStory;
