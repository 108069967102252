import React from "react";

const Timeline = () => {
  const events = [
    { year: 2016, title: "als", description: "Bhavishya NGO was founded, starting with 2 kids in Noida's Sector 71 & the opening of our first rented space. A significant milestones was achieved by organizing cultural & sports day events for over 100 kids on Independence & Republic Day"},
    { year: 2018, title: "Foundation Year", description: "In 2018, Bhavishya NGO made a significant impact in education, supporting 100 underprivileged kids with regular education and facilitating school admissions for over 1,000 disadvantaged kids. We also established a free Bhavishya Education Centre and launched a Computer Skill Development Centre in Bihar with 20 students." },
    { year: 2020, title: "First Education Drive", description: "By 2020, Bhavishya NGO expanded its educational initiatives, opening new centres in West Bengal and Uttar Pradesh. We also established a Livelihood Centre in Nainital for women's empowerment. During the pandemic, we collaborated with UP Police to provide essentials to those in need." },
    { year: 2022, title: "First Education Drive", description: "By 2022, Bhavishya NGO's free education centre grew to 600 students and facilitated school admissions for 3,000 underprivileged kids. We established our 4 core pillars: Environment, Education, Health, and Livelihood, and conducted medical health camps in slum areas." },
    { year: 2024, title: "First Education Drive", description: "By 2024, Bhavishya NGO expanded its footprint to South India with a new centre in Bengaluru. Our education centres now empower 1300+ students, and we've launched a school with 200 students. We've also established an old age home in Uttarakhand and conducted multiple plantation drives, furthering our commitment to community service and sustainability." },
  ];

  return (
    <section className="bg-[#FEF7ED] py-10 px-6 md:px-16 font-[Poppins]">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800 text-left mb-8">Our Journey</h2>
        <div className="relative border-l-4 border-[#EB5A57] pl-6">
          {events.map((event, index) => (
            <div key={index} className="mb-8">
              <div className="flex items-center">
                <div className="bg-[#EB5A57] text-white text-sm font-bold py-1 px-3 rounded-full">
                  {event.year}
                </div>
                {/* <h3 className="ml-4 text-xl font-semibold text-gray-800 text-left">{event.title}</h3> */}
              </div>
              <p className="mt-2 text-gray-700 text-left">{event.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Timeline;
