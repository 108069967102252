import React from "react";

const DoubleColumn = ({ title, description, img, position, clrClass }) => {
  return (
    <div
      className={`w-full ${clrClass} rounded-lg px-10 py-10 md:px-20 md:py-20`}
      id="about-us"
    >
      {/* Content Section */}
      <div
        className={`flex flex-col lg:flex-row items-start lg:space-x-20 ${
          position === "left" ? "" : "lg:flex-row-reverse"
        }`}
      >
        {/* Image Section */}
        <div className="lg:w-1/2">
        <div
            className={`font-Poppins font-extrabold text-4xl md:text-5xl mb-6 invisible ${
              position === "left" ? "text-left" : "text-left"
            }`}
          >
            {title}
          </div>
          <img
            src={img}
            alt={title}
            className="rounded-lg w-full object-cover"
          />
        </div>

        {/* Text Section */}
        <div className="lg:w-1/2 mt-8 lg:mt-0">
          {/* Title */}
          <div
            className={`font-Poppins font-extrabold text-4xl md:text-5xl mb-6 ${
              position === "left" ? "text-left" : "text-left"
            }`}
          >
            {title}
          </div>
          {/* Description */}
          <ul
            className={`list-disc ${
              position === "left" ? "pl-6" : "lg:pr-6"
            } font-Poppins text-md leading-7 lg:leading-8 space-y-4 md:text-xl`}
          >
            {description.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DoubleColumn;
