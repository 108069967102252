import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { CenterDetails } from "../assets";

// Define the red marker icon
const redIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  shadowSize: [41, 41], // size of the shadow
});

const centers = CenterDetails;

// Component to update the map center
const MapUpdater = ({ center }) => {
  const map = useMap();
  map.setView(center, 12); // Set the new center and zoom
  return null;
};

const CentersPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [mapCenter, setMapCenter] = useState([28.5743, 77.3617]); // Default center

  const filteredCenters = centers.filter((center) =>
    center.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Update map center when a search term matches a center
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const matchingCenter = centers.find((center) =>
      center.name.toLowerCase().includes(term.toLowerCase())
    );

    if (matchingCenter) {
      setMapCenter(matchingCenter.coordinates);
    }
  };

  return (
    <div>
      <div style={{ padding: "2rem", fontFamily: "'Poppins', sans-serif" }}>
        <h1
          style={{
            textAlign: "center",
            fontWeight: 700,
            fontSize: "2rem",
            marginBottom: "1rem",
          }}
        >
          Our Presence
        </h1>
        <p
          style={{
            textAlign: "center",
            color: "#4f4f4f",
            fontSize: "1rem",
            lineHeight: "1.6",
            maxWidth: "70rem",
            margin: "0 auto",
          }}
        >
          Reaching underserved communities with quality education, we empower children with knowledge, skills, and opportunities for a brighter future. Our presence transforms lives, one child at a time, through learning and hope.
        </p>
      </div>

      <div
        style={{
          display: "flex",
          margin: "0 2rem 2rem 2rem", // Equal margin on all 4 sides
          borderRadius: "20px",
          border: "1px solid black",
          overflow: "hidden", // Ensures clean edges
        }}
      >
        {/* Map Section */}
        <div style={{ flex: 1 }}>
          <MapContainer
            center={mapCenter}
            zoom={12}
            style={{
              width: "100%",
              height: "500px",
            }}
          >
            <MapUpdater center={mapCenter} />
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {centers.map((center) => (
              <Marker
                key={center.id}
                position={center.coordinates}
                icon={redIcon}
              >
                <Popup>
                  <div>
                    <h4>{center.name}</h4>
                    <p>{center.description}</p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>

        {/* Search & Centers Section */}
        <div
          style={{
            flex: 1,
            backgroundColor: "#f94144",
            color: "white",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Ensures even spacing
          }}
        >
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              width: "100%",
              padding: "0.5rem", // Reduced padding to decrease height
              marginBottom: "1rem",
              border: "none",
              borderRadius: "30px", // Fully rounded corners
              fontSize: "0.9rem", // Slightly smaller font size to match reduced height
              boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
              color: "black",
              outline: "none", // Removes blue outline on focus
            }}
          />
          <div
            style={{
              flex: 1,
              maxHeight: "400px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {filteredCenters.length > 0 ? (
              filteredCenters.map((center) => (
                <div
                  key={center.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "1rem",
                    backgroundColor: "#ff6f61",
                    borderRadius: "12px",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div>
                    <h4 style={{ margin: "0", fontSize: "1.1rem" }}>
                      {center.name}
                    </h4>
                    <p
                      style={{
                        margin: "0.5rem 0",
                        fontSize: "0.9rem",
                        color: "#ffe5e5",
                      }}
                    >
                      {center.description}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p style={{ textAlign: "center", fontStyle: "italic" }}>
                No centers found.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CentersPage;
