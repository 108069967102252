import React from "react";
import { EdSupportPageImg } from "../../assets";

const EdSupportTab = () => {
  return (
    <div className="">
      <div className="px-12 border-2 m-4 border-black rounded space-y-8 py-4 md:space-y-12 lg:py-8 lg:space-y-16">
        <div className="space-y-6 md:space-y-8">
          <div className="font-Cheri text-center text-secondaryBg text-4xl font-bold md:text-6xl">
            Seekh.
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:w-full md:space-x-10">
            <div className="-ml-4 md:w-1/2 md:ml-0">
              <img src={EdSupportPageImg} alt="Ed Support bg" />
            </div>
            <div className="font-Poppins text-justify md:w-1/2 lg:text-2xl lg:leading-10">
              Vikash founded Bhavishya with only 2 underprivileged kids
              then he has grown the organization from an informal,
              volunteer-led activity for Underprivileged children, into a
              leading organization working in the area of educating and
              mentoring some of India’s poorest children and young people in
              Noida region. It was always his dream to give education to
              underprivileged children and make them stand equal with the world.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EdSupportTab;
