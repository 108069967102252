import React from "react";
import { Link } from "react-router-dom";

const stories = [
  {
    id: 1,
    name: "From Struggle to Success: Aditya's Journey With Us",
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story1.jpeg"
  },
  {
    id: 2,
    name: "Bridging the Digital Divide : Madhubani Education Center",
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story2a.jpeg"
  },
  {
    id: 3,
    name: "Empowering Women : An Initiative",
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story3.jpeg"
  },
  {
    id: 4,
    name: "Empowering Female Artists: Kala Sangini Initiative",
    img: "https://ik.imagekit.io/bhavishya/web-final/Programs/kala/kala11.jpeg?updatedAt=1739992158886"
  },
  {
    id: 5,
    name: "Bhavishya NGO's COVID-19 Relief Efforts: A Success Story",
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story5a.jpeg"
  },
  {
    id: 6,
    name: "Bhavishya NGO's 'Sab Padhe Sab Badhe' Initiative: Empowering Education in Noida",
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story6.jpeg"
  },
  {
    id: 7,
    name: "Bhavishya NGO: A Beacon of Hope",
    img: "https://ik.imagekit.io/bhavishya/web-final/Success_Story/story7b.jpeg"
  }
];

const SuccessStory = () => {
  return (
    <div className="bg-white min-h-screen p-10">
      <h1 className="text-4xl font-bold text-center mb-12">Turning Challenges into Achievements</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 justify-center">
        {stories.map((story) => (
          <Link to={`/story/${story.id}`} key={story.id} className="relative rounded-lg overflow-hidden shadow-lg block">
            <img src={story.img} alt={story.name} className="w-full h-80 object-cover" /> {/* Increased height from h-60 to h-80 */}
            <div className="absolute bottom-0 left-0 w-full bg-[#EB5A57] text-white p-3">
              <h2 className="text-lg font-bold">{story.name}</h2>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SuccessStory;
