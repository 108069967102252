import React from "react";

const SingleProgramContainer = ({ title, description, img }) => {
  return (
    <div className="space-y-6 p-6">
      <div
        className="bg-secondaryBg text-white rounded-xl overflow-hidden w-full shadow-lg p-5 flex flex-col items-center space-y-2"
        style={{
          height: "400px", // Fixed height for the entire card
        }}
      >
        {img && (
          <div className="w-full">
            <img
              src={img}
              alt={title}
              className="object-cover w-full h-60 rounded-lg" // Fixed image height
            />
          </div>
        )}
        <div
          className="text-left space-y-2 flex-grow"
          style={{
            overflow: "hidden", // Prevent content overflow
          }}
        >
          <h2 className="text-2xl md:text-2xl font-bold">{title}</h2>
          <p
            className="text-sm md:text-sm"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3, // Limit text to 3 lines
              WebkitBoxOrient: "vertical",
            }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SingleProgramContainer;
