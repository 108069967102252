import React, { useState } from "react";
import { GiCancel, GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Logo } from "../../assets";

const SmallScreenNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  let navLinks = {
    "About US": "/#about-us",
    Program: "/programs",
    // Campaign: "/campaigns",
    "Sucess Story": "/successstory",
    "Our Presence": "/ourcenters",
    "Get in touch": "/contact",
  };

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-secondaryBg">
      {!isOpen ? (
        <div className="p-2 flex items-center justify-between md:py-2 md:px-4 py-0">
          <div className="">
            <Link to="/">
              <img src={Logo} alt="Bhavishya Logo" className="w-16 md:w-28" />
            </Link>
          </div>
          <GiHamburgerMenu
            className="fill-white border-2 rounded-md w-6 h-6  border-white md:w-12 md:h-12"
            onClick={onClick}
          />
        </div>
      ) : (
        <div className="p-2 md:p-12">
          <div className="flex justify-end pt-2">
            <GiCancel
              className="fill-white border-2 rounded-md w-8 h-8 border-white md:w-12 md:h-12"
              onClick={onClick}
            />
          </div>

          <div className="bg-secondaryBg space-y-10 h-screen pt-20 md:space-y-16">
            <div className="">
              <Link to="/">
                <img
                  src={Logo}
                  alt="Bhavishya Logo"
                  className="mx-auto w-1/3"
                />
              </Link>
            </div>

            <div className="flex flex-col text-center space-y-2 md:space-y-4">
              {Object.keys(navLinks).map((item, i) => (
                <HashLink
                  className="font-Poppins font-[800] text-white text-2xl md:text-4xl"
                  key={item}
                  to={navLinks[item]}
                  onClick={onClick}>
                  {item}
                </HashLink>
              ))}
            </div>
            <div className="text-center">
              <Link
                to="/donate"
                className="bg-black px-6 py-4 text-white font-[800] rounded-md text-2xl md:text-4xl hover:border-black hover:bg-white hover:text-black"
                onClick={onClick}>
                Donate us
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmallScreenNavbar;
