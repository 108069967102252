import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProgramsData } from "../assets";
import { SingleProgramContainer } from "../components";

const Campaigns = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("all");

  const filteredPrograms = activeTab === "all"
    ? ProgramsData
    : ProgramsData.filter((item) => item.category === activeTab);

  const uniqueCategories = [...new Set(ProgramsData.map(item => item.category))];

  return (
    <div className="bg-primaryBg p-4 pt-8 md:p-8 lg:p-12">
      <div className="bg-primaryBg rounded-lg space-y-4 pt-4">
        <div className="font-poppins font-bold text-2xl md:text-3xl lg:text-5xl text-center text-black">
          Our Pillars
        </div>

        {/* Scrollable Tabs Section for Mobile */}
        <div className="flex justify-center overflow-x-auto space-x-4 mb-6 mt-2 px-4 scrollbar-hide">
          <button
            className={`px-4 py-2 whitespace-nowrap rounded-md text-lg font-medium ${activeTab === "all" ? "text-[#EB5A57] font-bold" : "text-gray-500"
              }`}
            onClick={() => setActiveTab("all")}
          >
            All
          </button>
          {uniqueCategories.map((category) => (
            <button
              key={category}
              className={`px-4 py-2 whitespace-nowrap rounded-md text-lg font-medium ${activeTab === category ? "text-[#EB5A57] font-bold" : "text-gray-500"
                }`}
              onClick={() => setActiveTab(category)}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="flex flex-col items-center justify-center px-2 md:px-12 space-y-6 lg:grid lg:grid-cols-2 lg:space-y-0 lg:px-6">
          {filteredPrograms.map((item) => (
            <div
              key={item.title}
              onClick={() =>
                navigate(`/program/${item.title.toLowerCase().replace(/\s+/g, "-")}`, {
                  state: item,
                })
              }
              className="cursor-pointer"
            >
              <SingleProgramContainer
                title={item.title}
                description={item.description}
                img={item.img}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
